import React from 'react';
import * as PropTypes from "prop-types";
import { useLocation } from "@reach/router";

import '../../../styles/form/forms/callBackForm.scss';

import FosForm from '../../components/FosForm/FosForm.js';
import FormVerificationWrapper from '../../components/FormVerificationWrapper/FormVerificationWrapper.js';

import {
  FORM_FIELDS, 
  verificationFormType
} from './constants.js';

import { sendFosDataForm } from '../../models/form'; 

const SignUpFosForm = ({ openFormLinkTitle, canBeFixed = "false" }) => {

  const { state } = useLocation();

  const signUpFosFormOpened = state?.signUpFosFormOpened ?? false;

  return (
    <FormVerificationWrapper
      canBeFixed={canBeFixed}
      form={FosForm}
      formFieldValues={FORM_FIELDS}
      openFormLinkTitle={openFormLinkTitle}
      reachGoalTitle="fos_v4_send"
      submitForm={sendFosDataForm}
      signUpFormOpened={signUpFosFormOpened}
      verificationType={verificationFormType}
    />
  );
};

SignUpFosForm.propTypes = {
  canBeFixed: PropTypes.oneOf(['true', 'false']),
  openFormLinkTitle: PropTypes.string,
};

export default SignUpFosForm;
